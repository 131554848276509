<template>
  <page-main
    title="Here's what we've got so far:"
    aside="Take a quick look - you can go back and edit these at any time."
    nextText="Ready to move on to your Education?"
    buttonTabIndex="13"
    eventName="nav"
    @nav="nav('/Education')"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          v-for="(item, index) in displayList"
          :key="item.key"
          class="r-col"
        >
          <v-divider></v-divider>
          <status-bar v-model="item.name">
            <div class="d-flex justify-space-between align-center">
              <div>
                <div class="mt-n2">
                  <span class="text-caption">{{ item.title }}</span>
                </div>
                <div class="py-1 text-body-big">{{ item.name }}</div>
              </div>
              <div>
                <div>
                  <v-btn
                    :tabindex="index + 1"
                    tile
                    text
                    @click="nav(item.route)"
                  >
                    Edit
                  </v-btn>
                </div>
              </div>
            </div>
          </status-bar>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import StatusBar from "../components/StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ReviewGeneralInfo",
  data: () => ({}),
  components: {
    "status-bar": StatusBar,
  },
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    arradd(arr, value, route, title, key) {
      arr.push({ name: value, route: route, title: title, key: key });
    },
  },
  computed: {
    displayList: function () {
      let key = 0;

      let x = [];

      this.arradd(
        x,
        this.nameDisplay,
        "/Information",
        "General Information",
        key++
      );
      this.arradd(
        x,
        this.addressDisplay,
        "/Address",
        "Address and Contact",
        key++
      );
      this.arradd(x, this.adjectiveDisplay, "/Adjectives", "Adjectives", key++);
      this.arradd(x, this.roleDisplay, "/Adjectives", "Roles", key++);
      this.arradd(
        x,
        this.careerInterestsDisplay,
        "/Career",
        "Career Interests",
        key++
      );
      this.arradd(
        x,
        this.personalInterestsDisplay,
        "/Career",
        "Personal Interests",
        key++
      );
      this.arradd(
        x,
        this.hardSkillsDisplay,
        "/Hardskills",
        "Hard Skills",
        key++
      );
      this.arradd(
        x,
        this.licensesCertsDisplay,
        "/Hardskills",
        "Licenses and Certifications",
        key++
      );
      this.arradd(
        x,
        this.softSkillsDisplay,
        "/Softskills",
        "Soft Skills",
        key++
      );
      this.arradd(x, this.languagesDisplay, "/Softskills", "Languages", key++);
      this.arradd(
        x,
        this.professionalOrgDisplay,
        "/Organizations",
        "Professional Organizations",
        key++
      );
      this.arradd(
        x,
        this.volunteerOrgDisplay,
        "/Organizations",
        "Volunteer Organizations",
        key++
      );

      return x;
    },
    ...mapFields("name", { nameDisplay: "displayValue" }),
    ...mapFields("address", { addressDisplay: "displayValue" }),
    ...mapFields("adjective", ["adjectiveDisplay"]),
    ...mapFields("role", ["roleDisplay"]),
    ...mapFields("careerInterest", ["careerInterestsDisplay"]),
    ...mapFields("personalInterest", ["personalInterestsDisplay"]),
    ...mapFields("hardSkill", ["hardSkillsDisplay"]),
    ...mapFields("licenseCert", ["licensesCertsDisplay"]),
    ...mapFields("softSkill", ["softSkillsDisplay"]),
    ...mapFields("language", ["languagesDisplay"]),
    ...mapFields("organization", [
      "professionalOrgDisplay",
      "volunteerOrgDisplay",
    ]),
  },
  mounted() {},
};
</script>
